import React, { useState, useEffect } from 'react';
// import feat_circle from '../images/feat_circle.png';
import '../style/animation.css';
import '../style/responcive.css';
import '../style/style.css';
import '../style/result_style.css';
import '../style/bootstrap.min.css';
import '../style/other.css';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';
// import Header from '../components/header';
// import Footer from '../components/footer';
import '@splidejs/react-splide/css/core';
import Cookies from 'universal-cookie';
import { useLocation } from 'react-router-dom';
// import { Hourglass } from 'react-loader-spinner';
import Mobile_header from '../components/mobile_header';
import { useNavigate } from 'react-router-dom';
const Result = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([])
  // console.log("posts",posts)
  const [loading, setLoading] = useState(true);
  // console.log("loading", loading)
  const cookies = new Cookies();
  // const Loggedin = cookies.get('Loggedin');
  // console.log("Loggedin", Loggedin)
  // if (Loggedin == undefined && Loggedin == null) {
  //   window.location.href = '/login';
  // } else {
  //   console.log();
  // }
  // const access_token = cookies.get('access_token');
  // console.log("access_token",access_token);
  const gender_val = cookies.get('gender_val');
  // console.log("access_token",access_token);
  const select_skin_val = cookies.get('select_skin_val');
  // console.log("select_skin_val",select_skin_val);
  const age_val = cookies.get('age_val');
  // console.log("age_val",age_val);

  const [imagePath1, setImagePath] = useState('');
  const location = useLocation();

  useEffect(() => {
    const { imagePath } = location.state;
    if (imagePath) {
      setImagePath(imagePath);
      reportApiCall(imagePath, select_skin_val, age_val, gender_val);
    }
  }, [location.state, age_val, gender_val, select_skin_val]);

  const reportApiCall = (imagePath, select_skin_val, age_val, gender_val) => {
    setLoading(true);
    const myHeaders = new Headers()
    // myHeaders.append('Authorization', 'Bearer kM3rJP3j46FDXCfu6aCQf20d5YEunA')
    myHeaders.append('Authorization', 'Bearer ' + cookies.get('access_token'))
    myHeaders.append('Content-Type', 'application/json')
    const raw = JSON.stringify({
      "image": imagePath,
      "skin_type": select_skin_val,
      "age": age_val,
      "gender": gender_val,
      "partner_code": "7777"
    })
    // console.log("raw", raw);
    // fetch('http://127.0.0.1:8000/api/skinanalysis/skin_V4/', {
    fetch('https://api.skinfie.ai/api/skinanalysis/skin_V2/', {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response.json()
      })
      .then((data) => {
        const response_data = data.data;
        console.log("response_data",response_data)
        // setPosts(response_data); // Set the response data directly
        // setLoading(false);
        if (JSON.stringify(response_data).length === 2) {
          setPosts([])
          setLoading(true);
          navigate('/404');
        } else {
          setPosts(response_data)
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      })
  }

  return (
    <>
      <section className='mainSection overflow-auto'>
        <Mobile_header/>
        {/* <Header /> */}
        {loading.toString() === "false" ? (
          <>

            <div className='result_heading w-100 mt-4'>
              {imagePath1 && <img className='render_image' src={imagePath1} alt="profile" />}
              <h5 className='text-center text-uppercase'>your result</h5>
              <CircularProgressbar className="circle-progress" value={posts?.scores?.skin_health} text={`${posts?.scores?.skin_health}%`} />
            </div>
            <section className='result_output_section mt-5'>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>firmness</span>
                <span className='pe-3'>{posts?.scores?.firmness}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.firmness }}>
                  <div class="progress-bar" style={{ width: `${posts?.scores?.firmness}%`, backgroundColor: posts?.scores?.firmness_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>smoothness</span>
                <span className='pe-3'>{posts?.scores?.smoothness}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.smoothness }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.smoothness + "%", backgroundColor: posts?.scores?.smoothness_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>hydration</span>
                <span className='pe-3'>{posts?.scores?.hydration}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.hydration }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.hydration + "%", backgroundColor: posts?.scores?.hydration_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>dullness</span>
                <span className='pe-3'>{posts?.scores?.skin_dullness}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.skin_dullness }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.skin_dullness + "%", backgroundColor: posts?.scores?.skin_dullness_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>uneven skin tone</span>
                <span className='pe-3'>{posts?.scores?.uneven_skin}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.uneven_skin }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.uneven_skin + "%", backgroundColor: posts?.scores?.uneven_skin_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>fine lines & wrinkles</span>
                <span className='pe-3'>{posts?.scores?.face_wrinkles}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.face_wrinkles }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.face_wrinkles + "%", backgroundColor: posts?.scores?.face_wrinkles_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>dark sport</span>
                <span className='pe-3'>{posts?.scores?.dark_spots}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.dark_spots }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.dark_spots + "%", backgroundColor: posts?.scores?.dark_spots_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>Oxygen</span>
                <span className='pe-3'>{posts?.scores?.oxygen}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.oxygen }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.oxygen + "%", backgroundColor: posts?.scores?.oxygen_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>Dark Circle</span>
                <span className='pe-3'>{posts?.scores?.dark_circle}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.dark_circle }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.dark_circle + "%", backgroundColor: posts?.scores?.dark_circle_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>Acne</span>
                <span className='pe-3'>{posts?.scores?.acne}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.acne }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.acne + "%", backgroundColor: posts?.scores?.acne_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>Redness</span>
                <span className='pe-3'>{posts?.scores?.redness}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.redness }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.redness + "%", backgroundColor: posts?.scores?.redness_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>Eye Wrinkles</span>
                <span className='pe-3'>{posts?.scores?.eye_wrinkles}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.eye_wrinkles }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.eye_wrinkles + "%", backgroundColor: posts?.scores?.eye_wrinkles_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>Pigmentation</span>
                <span className='pe-3'>{posts?.scores?.pigmentation}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.pigmentation }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.pigmentation + "%", backgroundColor: posts?.scores?.pigmentation_hex_color }}></div>
                </div>
              </div>
              <div className='w-100 ps-4 progress_text'>
                <span className='text-capitalize fs-7'>Crows Feet</span>
                <span className='pe-3'>{posts?.scores?.crows_feet}%</span>
              </div>
              <div className="progressbar">
                <div class="progress" style={{ height: posts?.scores?.crows_feet }}>
                  <div class="progress-bar" style={{ width: posts?.scores?.crows_feet + "%", backgroundColor: posts?.scores?.crows_feet_hex_color }}></div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <div className='justify-content-center'>
            <div className='row'>
              <div className='col-12'>
                <div className='loder'>
                  <div className="scan">
                    {/* <h4 style={{color:"#9c735b"}}>Now analysing</h4>
                        <h5 style={{color:"#9c735b"}}>Key indicators on your face...</h5> */}
                    <div className='sleceted_gender text-center'>
                      <div className="array d-flex">
                        <span>[</span>
                        <div className='fs-5'>Now analysing <br />
                          Key indicators on your face....</div>
                        <span>]</span>
                      </div>
                    </div>
                    <div className="face" style={{ backgroundImage: `url(${imagePath1})`, backgroundSize: '300px',overflow:"hidden" }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <Footer /> */}
      </section>



    </>
  )
}

export default Result