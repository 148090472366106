import React from 'react'
import '../style/footer.css'
import footer_4_wave from '../images/footer_4_wave.png'
const Footer = () => {
  return (
    <footer className=''>
            <div className="footer">
                <h4 className='text-center text-capitalize' style={{color:"#000"}}>BWell HealthTech Private Limited.</h4>
                <h5 className='text-center text-capitalize' style={{color:"#000"}}>F-9, Triveni Commercial Complex, Near HDFC Bank, <br/>
Sheikh Sarai Phase - 1, New Delhi - 110017, India.<br/><strong>Phone: </strong>+91 8800685191<br/><strong>Email: </strong>info@iksanawellness.com</h5>
                <h2 className='text-center' style={{color:"#000"}}>powered by ima-appweb.com</h2>
            </div>
        </footer>

          )
}

          export default Footer