// import React from 'react'
import '../style/style.css'
import '../style/report.css'
import { BsFileEarmarkArrowDownFill, } from 'react-icons/bs'
import Header from '../components/header'
import React, { useEffect, useState } from "react";
import { GoArrowSwitch } from 'react-icons/go'
import { BsSearch } from 'react-icons/bs'
import '../style/report.css'
import 'react-datepicker/dist/react-datepicker.css'
import Footer from '../components/footer'
import Cookies from 'universal-cookie';
const Report = () => {
    const [posts, setPosts] = useState([])
    const startDate = new Date().toISOString().split('T')[0]
    const currentDate_val = new Date()
    currentDate_val.setDate(currentDate_val.getDate() + 1)
    const nextDay = currentDate_val.toISOString().split('T')[0]
    const [start_date, setstart_date] = useState(startDate)
    console.log("start_date :", start_date)
    const [end_date, setend_date] = useState(nextDay)
    console.log("end_date :", end_date)
    const [currentDate, setCurrentDate] = useState(new Date())
    console.log("currentDate :", currentDate)

    const cookies = new Cookies();
    // const Loggedin = cookies.get('Loggedin')
    // console.log("Loggedin", Loggedin)
    // if (Loggedin == undefined && Loggedin == null) {
    //     window.location.href = '/login'
    // } else {
    //     console.log()
    // }

    const handleStartDateChange = (event) => {
        const start_date = event.target.value;
        setstart_date(start_date);
    };

    const handleEndDateChange = (event) => {
        const end_date = event.target.value;
        setend_date(end_date);
    };

    function searchDateWiseReport() {
        reportApiCall(start_date, end_date);
    }

    useEffect(() => {
        reportApiCall(startDate, nextDay)
    }, [startDate, nextDay])


    const reportApiCall = (start_date, end_date) => {
        console.log("reportApiCall start_date " + start_date)
        console.log("reportApiCall end_date " + end_date)
        cookies.set('start_date', start_date)
        cookies.set('end_date', end_date)
        const myHeaders = new Headers()
        // myHeaders.append('Authorization', 'Bearer ' + cookies.get('access_token'))
        myHeaders.append('Content-Type', 'application/json')
        const raw = JSON.stringify({
            "from_date": start_date,
            "to_date": end_date,
            "partner_code": "7777"
        })
        console.log("raw", raw);
        fetch('https://api.skinfie.ai/api/partner/getUserPartnerAllReports/', {
        // fetch('http://127.0.0.1:8000/api/partner/getUserPartnerAllReports/', {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText)
                }
                return response.json()
            })
            .then((data) => {
                const response_data = data.data
                // console.log("response_data :"+JSON.stringify(response_data))
                // console.log("response_data :"+JSON.stringify(response_data).length)
                if (JSON.stringify(response_data).length === 2) {
                    setPosts([])
                } else {
                    const pagination_val = 10
                    const citrus = response_data.slice(0, pagination_val)
                    // console.log("response_data1996 :" + response_data.data)
                    setPosts(citrus)
                }
            })
            .catch((err) => {
                console.log(err.message)
            })
    }
    const download_url = `https://api.skinfie.ai/api/partner/downloadExcelFile/?start_date=${cookies.get('start_date')}&end_date=${cookies.get('end_date')}&partner_code=7777`
    return (
        <>
            <Header />
            <main id="main">
                <section id="features" className="features feature_report">
                    <div className="container">
                        <div class="container d-flex main_header_content">
                            <div class="heading">
                                <h3>Reports</h3>
                                <p><span style={{color:"#986e55"}}>{currentDate.toLocaleDateString(undefined, { weekday: 'long' })}</span> {currentDate.toLocaleDateString()} {currentDate.toLocaleTimeString()}</p>
                            </div>
                            <div class="datepicker">
                                <div class="startdatepicker">
                                    <div class="calendericon">
                                        {/* <i class="fa-solid fa-calendar"></i> */}
                                        <span>
                                            <input type="date"
                                                className="form-control form_controll_one"
                                                name="start_date"
                                                id="start_date"
                                                value={start_date}
                                                onChange={handleStartDateChange}
                                            /></span>
                                    </div>
                                </div>
                                <div class="icon">
                                    {/* <<i class="fa-solid fa-arrow-right-arrow-left"></i> */}
                                    <GoArrowSwitch className='arrow_icon' />
                                </div>
                                <div class="enddatepicker">
                                    <div class="calendericon">
                                        {/* <i class="fa-solid fa-calendar"></i> */}
                                        <span>
                                            <input type="date"
                                                className="form-control"
                                                name="end_date"
                                                id="end_date"
                                                value={end_date}
                                                onChange={handleEndDateChange}
                                            /></span>
                                    </div>
                                </div>
                            </div>
                            <div class="icons">
                                <button onClick={searchDateWiseReport} className="btn btn-danger ima-btn me-1 icon_span">
                                    <BsSearch />
                                </button>
                                <a href={download_url} className="btn btn-primary ima-btn icon_span" type="button" style={{ '--bs-btn-bg': '#986e55', '--bs-btn-border-color': '#986e55' }}>
                                    <BsFileEarmarkArrowDownFill />
                                </a>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 my-auto" data-aos="fade-left">
                                <div className="table-responsive text-nowrap">
                                    <table className="table table-bordered border-primary mb-0" id="detailsTable">
                                        <thead>
                                            <tr>
                                                <th rowSpan="2">S.No</th>
                                                <th colSpan="21" className="text-center">Skin Scores</th>
                                            </tr>
                                            <tr>
                                                <th>Date</th>

                                                <th>Time</th>
                                                <th>Gender</th>
                                                <th>Age</th>
                                                <th>Skin Type</th>
                                                <th>Skin Health Scores</th>
                                                <th>Dark Circle</th>
                                                <th>Skin Dullness</th>
                                                <th>Dark Spots</th>
                                                <th>Acne</th>
                                                <th>Uneven Skin</th>
                                                <th>Face Wrinkles</th>
                                                <th>Eye Wrinkles</th>
                                                <th>Crows Feet</th>
                                                <th>Shine</th>
                                                <th>Redness</th>
                                                <th>Pigmentation</th>
                                                <th>Firmness</th>
                                                <th>Oxygen</th>
                                                <th>Smoothness</th>
                                                <th>Hydration</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {posts.map(({ add_date, add_time, gender, age,
                                                skin_type, dark_circle, skin_dullness, dark_spots,
                                                acne, uneven_skin, face_wrinkles, eye_wrinkles,
                                                crows_feet, shine, redness, pigmentation, firmness, oxygen, smoothness,
                                                hydration, skin_health }, index) => {
                                                // console.log("key", id)
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{add_date}</td>
                                                        <td>{add_time}</td>
                                                        <td>{gender}</td>
                                                        <td>{age}</td>
                                                        <td>{skin_type}</td>
                                                        <td>{skin_health}</td>
                                                        <td>{dark_circle}</td>
                                                        <td>{skin_dullness}</td>
                                                        <td>{dark_spots}</td>
                                                        <td>{acne}</td>
                                                        <td>{uneven_skin}</td>
                                                        <td>{face_wrinkles}</td>
                                                        <td>{eye_wrinkles}</td>
                                                        <td>{crows_feet}</td>
                                                        <td>{shine}</td>
                                                        <td>{redness}</td>
                                                        <td>{pigmentation}</td>
                                                        <td>{firmness}</td>
                                                        <td>{oxygen}</td>
                                                        <td>{smoothness}</td>
                                                        <td>{hydration}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main >

            <Footer />

            {/* <!-- ====== start to toep button ====== --> */}
            <a href="#" className="to_top bg-gray rounded-circle icon-40 d-inline-flex align-items-center justify-content-center">
                <i className="bi bi-chevron-up fs-6 text-dark"></i>
            </a>
        </>
    )
}

export default Report