import React from 'react'
import Mobile_header from '../components/mobile_header'

const ErrorPage = () => {
    return (
        <>
            <section className='mainSection overflow-auto'>
                <Mobile_header />
                <div className='mt-5' style={{height:"85vh"}}>
                <span className='mt-4 text-center w-100 fs-4 text-capitalize fw-400' style={{color:"#000", fontFamily:"'IBM Plex Serif', serif"}}>Sorry!! </span>
                <div className="reson mt-3">
                    <span className='mt-2 p-2 text-center lh-4 fw-300 w-100' style={{color:"#000", letterSpacing:".5px", fontFamily:"'IBM Plex Serif', serif"}}>SkinFie AI was unable to generate the result for you. </span>
                    <span className='mt-2 p-2 text-center lh-4  fw-300 w-100' style={{color:"#000", letterSpacing:".5px", fontFamily:"'IBM Plex Serif', serif"}}><b>Reason: </b>Image Clicked by you can be one problem, if you think, you ve clicked a clear and good image. Continue with first option i.e Re Generate else press take test again</span>
                </div>
                </div>
            </section>
        </>
    )
}

export default ErrorPage;
