import React, { useState, useEffect } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
// import { Link } from 'react-router-dom'
import skinfieLogo from '../images/iksana-wellness-logo.png'
import '../style/header.css'
import '../style/navbar.css'
import Cookies from 'universal-cookie';
const Header = () => {
    const cookies = new Cookies(); // Initialize cookies
    // const [Loggedin, setLoggedin] = useState(null);
    // useEffect(() => {
    //     const loggedInCookie = cookies.get('Loggedin');
    //     setLoggedin(loggedInCookie); // Set the value of Loggedin using useState
    //     console.log('Loggedin:', loggedInCookie);
    //     console.log(typeof loggedInCookie);
    // }, []);
    function deleteAllCookies() {
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i]
          const eqPos = cookie.indexOf('=')
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
        }
      }
      const handleLogout = () => {
        deleteAllCookies()
        window.location.href = '/login'
      }
    return (
        <div>
            <Navbar expand="lg" className="navbar-light style-4">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={skinfieLogo} alt="no show images" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarSupportedContent" />
                    <Navbar.Collapse className="mt-0" id="navbarSupportedContent">
                        <Nav className="m-auto mb-2 mb-lg-0">
                        </Nav>
                        <div className="nav-side">
                            <div className="d-flex align-items-center">
                            <a href={'/analysis'} className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold me-2">
                                        <span>Take a Test</span>
                                    </a>
                                    <a href="/reports" className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold me-2">
                                        <span>Reports</span>
                                    </a>
                                {/* {Loggedin !== true ?
                                    <a href={'/login'} className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold me-2">
                                        <span>Take a Test</span>
                                    </a>
                                    :
                                    <>
                                    <a href={'/analysis'} className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold me-2">
                                        <span>Take a Test</span>
                                    </a>
                                    <a href="/reports" className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold me-2">
                                        <span>Reports</span>
                                    </a>
                                    <a className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold" onClick={handleLogout} style={{ color: "#fff", backgroundColor: "#dc3545", borderColor: "#dc3545" }}>
                                        <span>Logout</span>
                                    </a>
                                    </>
                                } */}
                            </div>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header