import React, { useState, useRef } from 'react'
// import header_4 from '../images/header_4.png'
import femelimage from '../images/femelimage.png'
import pier from '../images/pier.png'
// import Header from '../components/header'
import Cookies from 'universal-cookie';
import Webcam from 'react-webcam';
import { useNavigate } from 'react-router-dom';
// import Footer from '../components/footer'
import Mobile_header from '../components/mobile_header'
const Analysis = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    // console.log("currentStep :",currentStep);
    const cookies = new Cookies();
    // const Loggedin = cookies.get('Loggedin')
    // console.log("Loggedin",Loggedin)
    // if (Loggedin == undefined && Loggedin == null) {
    //     window.location.href = '/login'
    // } else {
    //     console.log()
    // }

    let [count, setCount] = useState(22);
    cookies.set('age_val', count);
    function incrementCount() {
        count = count + 1;
        setCount(count);
    }
    function decrementCount() {
        count = count - 1;
        setCount(count);
    }


    const [selectedValue, setSelectedValue] = useState(''); // State to track the selected gender

    // Event handler for radio button changes
    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    // Event handler for clicking the "Next" button
    const handleContinueClick = (nextStep) => {
        // Check if a gender is selected before proceeding
        if (selectedValue === '') {
            alert('Choose an option!');
        } else {
            // Handle the logic for moving to the next step
            console.log(`Selected gender: ${selectedValue}`);
            cookies.set('gender_val', selectedValue);
            setCurrentStep(nextStep)
            // You can implement your logic to navigate to the next step here
        }
    };


    const [selectedSkinType, setSelectedSkinType] = useState('');

    const handleRadioChangeSkinType = (event) => {
        setSelectedSkinType(event.target.value);
    };
    const handleNextClick = (nextStep) => {
        if (selectedSkinType === '') {
            alert('Choose an option!');
        } else {
            console.log(`Selected skin type: ${selectedSkinType}`);
            cookies.set('select_skin_val', selectedSkinType);
            // Proceed to the next step
            handleContinueClick(4); // Replace 4 with the appropriate step number
            //   setCurrentStep(nextStep)
        }
    };

    const [imagePath, setImagePath] = useState(''); // State to store the image path
    const webcamRef = useRef(null);
    const videoConstraints = {
        width: 1280,
        height: 1280,
        facingMode: "user",
    };

    // Function to handle image capture and save the Base64 image path
    const handleCaptureImage = async () => {
        const video = webcamRef.current.video;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        console.log("canvas.width",canvas.width)
        canvas.height = video.videoHeight;
        console.log("canvas.height",canvas.height)
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const base64Image = canvas.toDataURL('image/jpeg');
        // Store the Base64 image path in state
        setImagePath(base64Image);
        navigate('/result', { state: { imagePath: base64Image } });
    };

    return (
        <>
            <section className='mainSection overflow-auto'>
                <Mobile_header />
                {/* <Header /> */}
                {/* <!-- step 1 --> */}
                {currentStep === 1 && (
                    <section className="steps">

                        {/* <!-- form --> */}
                        <form id="step1" method="post">
                            <div className="genderheading text-center mt-5">Select Gender</div>
                            <div className="breackets mt-2">
                                {/* <p>Men's and women's skin exhibit distinct structural differences.</p> */}
                                <div className='sleceted_gender text-center'>
                                    <div className="array d-flex">
                                        <span>[</span>
                                        <div className='fs-5'>Men's and women's skin exhibit <br />
                                            distinct structural differences.</div>
                                        <span>]</span>
                                    </div>
                                </div>
                                <div className="img_picker_form">
                                    <div className="row">
                                        <div className="col-12 my-auto">
                                            <div className="img_picker_radio_fild">
                                                <input
                                                    className="checkmark"
                                                    type="radio"
                                                    name="op1"
                                                    value="Female"
                                                    checked={selectedValue === 'Female'} // Check if "Female" is selected
                                                    onChange={handleRadioChange}
                                                />

                                                <label className='img_picker'> <img src={femelimage} alt="" />Women</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 my-auto">
                                            <div className="img_picker_radio_fild">

                                                <input
                                                    className="checkmark"
                                                    type="radio"
                                                    name="op1"
                                                    value="Male"
                                                    checked={selectedValue === 'Male'} // Check if "Male" is selected
                                                    onChange={handleRadioChange}
                                                />
                                                <label className='img_picker'><img src={pier} alt="" /> Men</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="gender_btn">
                                    {/* <button type='button' className='previous_btn' onClick={()=>handleContinueClick(0)}>Previous</button> */}
                                    <button type='button' className='next_btn' onClick={() => handleContinueClick(2)}>Next</button>
                                </div>
                            </div>
                        </form>
                    </section>
                )}

                {/* <!-- step 2 --> */}
                {currentStep === 2 && (
                    <section className="steps">

                        {/* <!-- form --> */}
                        <form id="step2" method="post">
                            <div className="genderheading text-center mt-5 text-capitalize">How old are you?</div>
                            <div className="breackets mt-5">
                                {/* <p>The signs of aging can start to manifest as early as the age of 25.</p> */}
                                <div className='sleceted_gender text-center'>
                                    <div className="array d-flex">
                                        <span>[</span>
                                        <div className='fs-5'>The signs of aging can start to<br />
                                        manifest as early as the age of 25.</div>
                                        <span>]</span>
                                    </div>
                                </div>
                                <div className="age ">
                                    <button type="button" className="minus" onClick={decrementCount} style={{backgroundColor: "#f1f1f1",color: "#000",outline: "solid 1px #000"}}>-</button>
                                    <div className="count" style={{color: "#000"}}>{count}</div>
                                    <button type='button' className='plus' onClick={incrementCount} style={{backgroundColor: "#f1f1f1",color: "#000",outline: "solid 1px #000"}}>+</button>
                                </div>
                                <div className="gender_btn mt-5">
                                    <button type='button' className='previous_btn' onClick={() => handleContinueClick(1)}>Previous</button>
                                    <button type='button' className='next_btn' onClick={() => handleContinueClick(3)}>Next</button>
                                </div>
                            </div>
                        </form>

                    </section>
                )}

                {/* <!-- step 3 --> */}
                {currentStep === 3 && (
                    <section className="steps">

                        {/* <!-- form --> */}
                        <form id="step3" method="post">
                            <div className="genderheading text-center mt-5 fs-4"> Which skin type describes you the best?</div>
                            <div className="breackets mt-2">
                                <div className='sleceted_gender text-center'>
                                    <div className="array d-flex">
                                        <span>[</span>
                                        <div className='fs-6'>Were you aware that the signs of aging may <br />
                                        become apparent as early as the age of
                                25?</div>
                                        <span>]</span>
                                    </div>
                                </div>
                                <div className="form-inner">
                                    <div className="row">
                                        <div className="col-12 my-auto">
                                            <div className="bounce-left radio-field">
                                                <input
                                                    className="checkmark"
                                                    type="radio"
                                                    name="op3"
                                                    value="Oily"
                                                    checked={selectedSkinType === 'Oily'}
                                                    onChange={handleRadioChangeSkinType}
                                                />
                                                <label>Oily</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 my-auto">
                                            <div className="bounce-left radio-field">
                                                <input
                                                    className="checkmark"
                                                    type="radio"
                                                    name="op3"
                                                    value="Dry"
                                                    checked={selectedSkinType === 'Dry'}
                                                    onChange={handleRadioChangeSkinType}
                                                />
                                                <label>Dry</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 my-auto">
                                            <div className="bounce-left radio-field">
                                                <input
                                                    className="checkmark"
                                                    type="radio"
                                                    name="op3"
                                                    value="Normal"
                                                    checked={selectedSkinType === 'Normal'}
                                                    onChange={handleRadioChangeSkinType}
                                                />
                                                <label>Normal</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="gender_btn mt-5">
                                    <button type='button' className='previous_btn' onClick={() => handleContinueClick(2)}>Previous</button>
                                    <button type='button' className='next_btn' onClick={handleNextClick}>Next</button>
                                </div>
                            </div>
                        </form>
                    </section>
                )}

                {/* <!-- step 4 --> */}
                {currentStep === 4 && (
                    <section className="steps">
                        <form id="step4" method="post">
                            <input type="hidden" name="csrfmiddlewaretoken" value="5heefKvaPo5vwzYVcVdzNckGn7rTMEMsaD4ruAosNXGUEbPe6hfI5sln6EXg8ALJ" />
                            {/* <h4 className="q-heading">
                            It's time for your
                        </h4> */}
                            <div className="genderheading text-center mt-5 text-capitalize">It's time for your</div>
                            <div className="breackets mt-4">
                                {/* <h1>No Makeup Selfie!</h1> */}
                                <div className='sleceted_gender text-center'>
                                    <div className="array d-flex">
                                        <span>[</span>
                                        <div className='fs-5'>No Makeup Selfie!</div>
                                        <span>]</span>
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6 col-sm-6 video_mobile_center">
                                            <div className="loading">
                                                <div className="spinner"></div>
                                            </div>
                                            <div className="control-panel" style={{ display: "none" }}>
                                            </div>

                                            <div className="vedio_mobile_bg">
                                                <Webcam
                                                    autoPlay
                                                    playsInline
                                                    muted
                                                    className="webCam"
                                                    id="video"
                                                    height={720}
                                                    width={1280}
                                                    audio={false}
                                                    mirrored={true}
                                                    ref={webcamRef}
                                                    videoConstraints={videoConstraints}
                                                />
                                            </div>
                                            <img src="" id="image" alt="" style={{ display: "none" }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="gender_btn mt-5">
                                    <button type='button' className='previous_btn' onClick={() => handleContinueClick(2)}>Previous</button>
                                    <button type='button' id="sub" className='next_btn apply' onClick={handleCaptureImage}>Capture</button>
                                </div>
                                </div>
                        </form>
                        <input type="hidden" name="file_url" id="file_url" value="/static/assets/face_detection_capture/js/main.js" />
                        <input type="hidden" name="script_file_url" id="script_file_url" value="/static/assets/face_detection_capture/js/index.js" />
                    </section>
                )}
                <div className="question overflow-hidden">
                </div>
                
                <div className="step-bar position-relative w-100">
                <div className="bar">
                    {currentStep >= 1 ? (
                        <div className="w-100 fill"></div>
                    ) : (
                        <div className="fill"></div>
                    )}
                </div>
                <div className="bar">
                    {currentStep >= 2 ? (
                        <div className="w-100 fill"></div>
                    ) : (
                        <div className="fill"></div>
                    )}
                </div>
                <div className="bar">
                    {currentStep >= 3 ? (
                        <div className="w-100 fill"></div>
                    ) : (
                        <div className="fill"></div>
                    )}
                </div>
                <div className="bar">
                    {currentStep >= 4 ? (
                        <div className="w-100 fill"></div>
                    ) : (
                        <div className="fill"></div>
                    )}
                </div>
                </div>
                {/* <Footer /> */}
            </section >

        </>
    )
}

export default Analysis