import React, { useState } from 'react'
import header_4 from '../images/header_4.png'
import feat_circle from '../images/feat_circle.png'
import Header from '../components/header'
// import { useNavigate } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import Footer from '../components/footer'
import Cookies from 'universal-cookie';
const Login = () => {
    const [email_id, setemail_id] = useState("")
    const [password, setpassword] = useState("")
    const [posts, setPosts] = useState([])
    const cookies = new Cookies();
    const handleSubmit = (e) => {
        e.preventDefault()
        const myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        const raw = JSON.stringify({
          "grant_type": "password",
          "username": email_id,
          "email": email_id,
          "password": password,
          "client_id": "P09a0XlyYA1MGcC2HB3wuxl029n81azdvmZWCw6R",
          "client_secret": "Uq94mOULi05QZUWP4rTWvnbl3rMtHmJvl4EKWBHuiE3xoEgFkMnC41UIns4V0Ic6jHQ49DarLtmE5nE8Upc5jmnLij7npEFfFO3T8cidPUAMqXtr4NCSufoGHEusuFel"
        })
        // console.log("raw",raw)
    
        fetch('https://api.skinfie.ai/auth/token/', {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        })
          .then((response) => {
            if (!response.ok) {
              throw Error(response.statusText)
            }
            return response.json()
          })
          .then((data) => {
            const response_data = data
            cookies.set('access_token', response_data.access_token);
            cookies.set('Loggedin', true);
            setPosts(data)
            window.location.href = '/analysis';
          })
          .catch((err) => {
            console.log(err.message)
          })
    }

    return (
        <>
            <Header />
            <section className="features pt-70 pb-70 style-4 h-100 text-start">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 mb-2 my-auto">
                            <div className="img">
                                <img className="p-3" src={header_4} alt="" width="100%" />
                            </div>
                        </div>
                        <div className="col-lg-2">
                        </div>
                        <div className="col-lg-5 mb-6 my-auto">

                            <form onSubmit={handleSubmit} autoComplete="off">
                                <div className="oxyy-login-register">
                                    <h3 className="mb-3">Login to your Iksana Wellness Account</h3>
                                    <input type="hidden" name="csrfmiddlewaretoken" value="avQNPIjTP7eebvqtG1NXAvzqZEZpDJlLfRG04ycbNGPDj7hMAnP6SLA7IbvMZFk2" />
                                    <div className="row mb-2">
                                        <div className="col-12 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="email_id" className="mb-1">Email Id:</label>
                                                <input onChange={(e) => setemail_id(e.target.value)} type="email" id="email_id" value={email_id} className="form-control" placeholder="Enter Email Id" required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-12 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="password" className="mb-1">Password:</label>
                                                <input onChange={(e) => setpassword(e.target.value)} value={password} type="password" id="password" className="form-control" placeholder="Enter Password" required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-12 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                            <button className="btn btn-primary w-100" type="submit">Log in</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <img src={feat_circle} alt="" className="img-circle" />
            </section>
            <Footer />
        </>
    )
}

export default Login