import React, { useState } from 'react';
// import MobileHeader from '../components/mobile_header';
import firstImage from '../images/WhatsApp/first_image.jpg';
import secondImage from '../images/WhatsApp/second_image.jpg';
import thirdImage from '../images/WhatsApp/third_image.jpeg';
import skinfieLogo from '../images/skinfie-logo.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import Home_header from '../components/home_header';
const MobileSlides = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const handleBulletClick = (index) => {
        // Swiper uses 0-based index
        setCurrentStep(index + 1);
    };

    const [agreeChecked, setAgreeChecked] = useState(false);

    const handleCheckboxChange = () => {
        setAgreeChecked(!agreeChecked);
    };

    const handleButtonClick = () => {
        if (agreeChecked) {
        navigate('/analysis');

        } else {
        alert("Please agree to the terms of privacy.");
        }
    };

    return (
        <section className="mainSection overflow-hidden" style={{textAlign:"center"}}>
            <Home_header />
            <Swiper pagination={{ clickable: true }} modules={[Pagination]} className="mySwiper">
                <SwiperSlide>
                    <div className="background">
                        <img src={firstImage} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="background">
                        <img src={secondImage} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='justify-content-center ima-pri-term'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='loder'>
                                    <div className="scan">
                                        <div className='sleceted_gender text-center'>
                                            <div className="array d-flex mb-4">
                                                <div className='fs-5'>SkinFie AI <br />
                                                    Powered Technology helps you <br />
                                                    understand you skin's uniqueneeds <br />
                                                    & shares personalized skin care <br />
                                                    recommendations for Healthy Skin!
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-check mb-2">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={agreeChecked}
                                                        onChange={handleCheckboxChange}
                                                        id="flexCheckDefault"
                                                    />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        I agree with your terms of privacy
                                                    </label>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    style={{ background: "#60c", color: "#fff" }}
                                                    onClick={handleButtonClick}
                                                >
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    );
};

export default MobileSlides;
